<template>
  <div class="logout-step" :class="{ mobile: isMobile }" data-t="logout-step">
    <div class="content">
      <h2 class="title">
        {{ t('userVerificationModal.oneStepFromBonus') }}
      </h2>
    </div>
    <div class="actions">
      <StButton
        :label="t('userVerificationModal.continueAndClaimBonus')"
        :size="isMobile ? 'l' : 'xl'"
        @click="emit('back')"
      />
      <StButton
        type="text-secondary"
        :label="t('userVerificationModal.logoutButton')"
        size="m"
        data-t="logout-button-azmb"
        @click="userStore.logout()"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from '@st/user/stores/useUserStore'

const { isMobile } = usePlatform()

const { t } = useI18n()

const emit = defineEmits<{
  back: []
}>()

const userStore = useUserStore()
</script>

<style scoped>
.logout-step {
  user-select: none;

  position: relative;

  overflow: hidden;
  display: flex;
  flex-direction: column;

  width: 480px;

  background: var(--background-base);
  border-radius: var(--border-radius-200);

  &::before {
    content: '';

    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;

    width: 100%;
    height: 236px;

    background-image: url('../images/gray.jpg');
    background-size: cover;
    border-radius: var(--border-radius-200, 16px) var(--border-radius-200, 16px)
      var(--border-radius-0, 0) var(--border-radius-0, 0);
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-300) var(--spacing-200) 0;
}

.title {
  position: relative;

  max-width: 340px;
  margin: 0;

  font: var(--mobile-title-1-semibold);
  color: var(--text-primary);
  text-align: center;
  text-wrap: balance;
}

.logout-step.mobile {
  width: auto;

  .title {
    font: var(--mobile-title-1-semibold);
  }
}

.actions {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-250);
  padding: var(--spacing-300) var(--spacing-200) var(--spacing-250);
}
</style>
