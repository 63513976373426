<template>
  <div
    class="user-verification-modal"
    :class="{ mobile: isMobile }"
    data-t="user-verification-modal"
  >
    <div class="finish-registration">
      <StIcon
        class="card"
        name="id-card-clip-solid"
        color="var(--icon-primary)"
        :size="isMobile ? 52 : 64"
      />
      <h2 class="header">{{ t('userVerificationModal.header') }}</h2>
    </div>
    <Transition name="fade" mode="out-in">
      <UserVerificationForm
        v-if="isReady"
        :initial-values="{ country: countryCode }"
        with-logout-button
        class="form"
        @logout="emit('logout')"
        @success="emit('success')"
      />
      <div v-else class="loader">
        <StSpinner size="32" />
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import UserVerificationForm from '../../UserVerificationForm/UserVerificationForm.vue'

const emit = defineEmits<{
  logout: []
  success: []
}>()

const { isMobile } = usePlatform()
const { countryCode, isReady } = useGeoAvailability()

const { t } = useI18n()
</script>

<style scoped>
.user-verification-modal {
  user-select: none;

  position: relative;

  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  width: 480px;
  max-height: calc(100dvh - var(--spacing-400));

  background: var(--background-base);
  border-radius: var(--border-radius-200);
  box-shadow: 0 0 80px -8px #1d1d2a;

  &::before {
    content: '';

    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;

    width: 100%;
    height: 236px;

    background: radial-gradient(
        48.54% 89.46% at 50% 0%,
        rgb(59 226 126 / 40%) 0%,
        rgb(0 67 46 / 0%) 85.92%
      ),
      radial-gradient(
        123.47% 89.46% at 50% 0%,
        rgb(14 156 95 / 70%) 0%,
        rgb(0 67 46 / 0%) 85.92%
      ),
      linear-gradient(
        0deg,
        var(--background-base) 0%,
        var(--background-base) 100%
      ),
      #d9d9d9;
    border-radius: var(--border-radius-200);
  }
}

.finish-registration {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-300);
}

.card {
  margin-bottom: var(--spacing-150);
}

.header {
  position: relative;
  z-index: 2;

  max-width: 270px;
  margin: 0;

  font: var(--desktop-text-xl-semibold);
  color: var(--text-primary);
  text-align: center;
}

.form {
  padding: var(--spacing-300) var(--spacing-300) var(--spacing-300)
    var(--spacing-300);
}

.user-verification-modal.mobile {
  width: 100%;

  .header {
    max-width: 190px;
    font: var(--mobile-title-2-semibold);
  }

  .finish-registration {
    padding: var(--spacing-250) var(--spacing-200);
  }

  .form {
    padding: var(--spacing-250) var(--spacing-200) var(--spacing-300);
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 456px;
}

.mobile .loader {
  width: auto;
  height: 376px;
}

.fade-enter-active,
.fade-leave-active {
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-from {
  transform: translateY(15px);
}
</style>
